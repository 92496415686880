import { getBuilderProjectDetails } from 'api/buildersApi'

const BuilderProjectDetails = {
    state: ()=>({
        builderProjectDetails:[],
    }),

    mutations:{
        setBuilderProjectDetails(state, payload){
            console.log('working',payload)
            state.builderProjectDetails = payload.data
        },
    },

    getters:{

    },

    actions:{
        fetchBuilderProjectDetails({commit}, project_id){
            return getBuilderProjectDetails(project_id).then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setBuilderProjectDetails', payload)
                        return payload
                    }
                })
        
        },
        
    }
}

export default BuilderProjectDetails