<template>
    <div
        v-if="Object.keys(builderProjectDetails).length > 0"
        class="builderprojectdetails-page"
    >
        <div class="project-container">
            <div class="project-breadcrumb">
                <ul class="breadcrumb">
                    <router-link
                        :to="{
                            name: 'Home',
                        }"
                    >
                        <li>Home</li>
                    </router-link>
                    <font-awesome-icon
                        class="breadcrumb-icon"
                        icon="chevron-right"
                    />
                    <router-link to="">
                        <li>My Projects</li>
                    </router-link>
                    <font-awesome-icon
                        class="breadcrumb-icon"
                        icon="chevron-right"
                    />
                    <li>{{ builderProjectDetails.project_data.name }}</li>
                </ul>
            </div>
            <div class="project-layout">
                <div class="project-details">
                    <h1 class="project-title">
                        {{ builderProjectDetails.project_data.name }}
                    </h1>
                    <p class="project-builder">
                        By
                        {{
                            builderProjectDetails.project_data.project_developer
                        }}
                    </p>
                    <div class="location">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="20"
                            fill="none"
                            viewBox="0 0 14 20"
                        >
                            <path
                                fill="#000"
                                d="M12.796 3.426c-.582-1.021-1.42-1.873-2.43-2.473-1.01-.6-2.159-.928-3.334-.951h-.308c-1.175.022-2.325.35-3.336.95-1.01.6-1.849 1.452-2.431 2.473-.61 1.04-.939 2.222-.955 3.427-.016 1.206.282 2.396.864 3.452l4.959 9.077.007.012c.105.184.258.337.441.443.184.107.392.163.604.163.213 0 .421-.056.605-.163.183-.106.335-.259.441-.443l.007-.012 4.957-9.079c.582-1.056.88-2.246.864-3.452-.016-1.205-.345-2.387-.955-3.427v.003zM6.875 9.063c-.556 0-1.1-.165-1.562-.474-.463-.309-.823-.748-1.036-1.262-.213-.514-.268-1.08-.16-1.625.109-.545.376-1.046.77-1.44.393-.393.894-.66 1.44-.769.545-.109 1.11-.053 1.624.16s.953.573 1.262 1.036c.31.462.474 1.006.474 1.562 0 .745-.297 1.46-.825 1.987-.527.528-1.241.824-1.987.825z"
                            />
                        </svg>
                        <p class="designerdetail-location">
                            {{ builderProjectDetails.project_data.location }}
                        </p>
                    </div>
                    <p class="project-description">
                        {{ builderProjectDetails.project_data.description }}
                    </p>
                    <div class="project-other-details">
                        <div class="detail">
                            <p>Configurations</p>
                            <p>
                                {{
                                    builderProjectDetails.project_data
                                        .configurations
                                }}
                            </p>
                        </div>
                        <div class="detail">
                            <p>Sizes</p>
                            <p>
                                {{ builderProjectDetails.project_data.sizes }}
                            </p>
                        </div>
                        <div class="detail">
                            <p>Price</p>
                            <p>₹ 33L-99L</p>
                        </div>
                        <div class="detail">
                            <p>Units</p>
                            <p>
                                {{ builderProjectDetails.project_data.units }}
                            </p>
                        </div>
                        <div class="detail">
                            <p>Project Area</p>
                            <p>
                                {{
                                    builderProjectDetails.project_data
                                        .project_area
                                }}
                            </p>
                        </div>
                    </div>
                    <div
                        v-if="builderProjectDetails.floorplan_data.length > 0"
                        class="project-floorplans"
                    >
                        <HeadAndSubTexts heading="Floor Plan" />
                        <div class="floorplans-list">
                            <div
                                v-for="floorplan in builderProjectDetails.floorplan_data"
                                :key="floorplan.id"
                                class="floorplan"
                            >
                                <div class="details">
                                    <h1 class="name">{{ floorplan.name }}</h1>
                                    <h1 class="area">{{ floorplan.area }}</h1>
                                </div>
                                <p class="view-floorplan">
                                    View Floor Plan
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        class="project-gallery-container"
                        v-if="builderProjectDetails.gallery_data.length > 0"
                    >
                        <div class="header">
                            <HeadAndSubTexts heading="Project Gallery" />
                            <p class="see-all">
                                see all
                            </p>
                        </div>

                        <!-- <VueSlickCarousel v-bind="settings">
                            <div
                                class="cards"
                                v-for="gallery in builderProjectDetails.gallery_data"
                                :key="gallery.id"
                            >
                                <Cards
                                    class="card"
                                    :cardname="gallery.name"
                                    :img="gallery.image.p_image"
                                />
                            </div>

                            <template #prevArrow>
                                <div class="prevArrow">
                                    <img
                                        class="left-arrow"
                                        src="@/assets/images/left-arrow.svg"
                                        alt=""
                                    />
                                </div>
                            </template>

                            <template #nextArrow>
                                <div class="nextArrow">
                                    <img
                                        class="right-arrow"
                                        src="@/assets/images/right-arrow.svg"
                                        alt=""
                                    />
                                </div>
                            </template>
                        </VueSlickCarousel> -->
                        <Carousel :settings="settings">
                            <div
                                class="cards"
                                v-for="gallery in builderProjectDetails.gallery_data"
                                :key="gallery.id"
                            >
                                <Cards
                                    class="card"
                                    :cardname="gallery.name"
                                    :img="gallery.image.p_image"
                                />
                            </div>
                        </Carousel>
                    </div>

                    <div
                        v-if="builderProjectDetails.virtual_data.length > 0"
                        class="project-virtual-tour"
                    >
                        <HeadAndSubTexts heading="Take a Virtual Tour" />
                        <div class="virtual-tour">
                            <div
                                v-for="virtual in builderProjectDetails.virtual_data"
                                :key="virtual.id"
                            >
                                <a :href="virtual.link" target="_blank">
                                    <OverLayCard
                                        alignment="center"
                                        ovalOverlay
                                        :title="virtual.floorplan"
                                        :description="virtual.description"
                                        :img="virtual.image.n_image"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div
                        class="project-designers-container"
                        v-if="builderProjectDetails.designer_data.length > 0"
                    >
                        <div class="header">
                            <HeadAndSubTexts heading="Designers" />
                            <p class="see-all">
                                <router-link
                                    :to="{
                                        name: 'DesignerListPage',
                                    }"
                                >
                                    see all
                                </router-link>
                            </p>
                        </div>
                        <div class="designers">
                            <div
                                v-for="designer in builderProjectDetails.designer_data"
                                :key="designer.id"
                            >
                                <router-link
                                    :to="{
                                        name: 'DesignerDetailsPage',
                                        params: { id: designer.id },
                                    }"
                                >
                                    <CircularImage
                                        :img="designer.image_meta.s_image"
                                        :name="designer.name"
                                    />
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <StyleBox
                        class="style-quiz-banner"
                        leftBanner
                        img="styleQuizPhoto2.png"
                        heading="Unsure About your Style ?"
                    />

                    <div
                        v-if="builderProjectDetails.offer_data.length > 0"
                        class="project-offers"
                    >
                        <div class="header">
                            <HeadAndSubTexts heading="Offers" />
                            <p class="see-all">
                                see all
                            </p>
                        </div>

                        <div class="offers-list">
                            <div
                                v-for="offer in builderProjectDetails.offer_data"
                                :key="offer.id"
                                class="offer"
                            >
                                <img :src="offer.s_image" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="project-contact">
                    <div class="contact-us">
                        <div class="input-group">
                            <input placeholder="Name" />
                            <input placeholder="Email Address" />
                            <input placeholder="Phone no" />
                        </div>
                        <Button primary name="Contact Us" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import BuilderProjectDetailsPageModule from 'store/modules/BuilderProjectDetailsPage'
import IsVisible from 'mixins/IsVisible'

import HeadAndSubTexts from 'componentsv2/HeadAndSubTexts'
import OverLayCard from 'componentsv2/OverLayCard'
import CircularImage from 'componentsv2/CircularImage'
import StyleBox from 'componentsv2/StyleBox'
import Button from 'componentsv2/Button'
import Carousel from 'componentsv2/Carousel'
import Cards from 'componentsv2/Cards'

export default {
    name: 'BuilderProjectDetailsPage',
    components: {
        HeadAndSubTexts,
        CircularImage,
        OverLayCard,
        StyleBox,
        Button,
        Carousel,
        Cards,
    },
    mixins: [RegisterStoreModule, IsVisible],
    data() {
        return {
            settings: {
                arrows: true,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                touchThreshold: 5,
                rows: 2,
            },
        }
    },
    computed: {
        ...mapState({
            builderProjectDetails: state =>
                state.BuilderProjectDetailsPageModule.builderProjectDetails,
        }),
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Project details',
    },
    created() {
        this.registerStoreModule(
            'BuilderProjectDetailsPageModule',
            BuilderProjectDetailsPageModule,
        )
        this.fetchBuilderProjectDetails(this.$route.params.id)
    },
    destroyed() {
        this.$store.unregisterModule('BuilderProjectDetailsPageModule')
    },
    methods: {
        ...mapActions({
            fetchBuilderProjectDetails: 'fetchBuilderProjectDetails',
        }),
    },
}
</script>

<style lang="scss" scoped>
@import './BuilderProjectDetailsPage.scss';
</style>
